#skills-wrap {
    height: 575px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #5b6467;
    background-image: linear-gradient(315deg, #C2C5CD 0%, #DCDCDC 74%);
    /* background: #C9CCD3;
    background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0.6) 0%, rgba(0,0,0,0.50) 100%);
    background-blend-mode: lighten; */
}

#skills-title {
    padding-bottom: 20px;
}


#inner-wrap {
    width: 560px;
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    /* overflow: scroll; */
    justify-content: center;
    background-color: white;
    border-radius: 10px;
}

.icon-and-title {
    display: flex;
    flex-direction: column;
    margin: 5px;
    text-align: center;
    background-color: rgb(210, 211, 214);
    border-radius: 5px;;
    padding: 10px;
    height: 90px;
    width: 100px;
    font-size: 13px;
}

.icon-and-title p {
    padding-bottom: 5px;
}

.five-icons {
    display: flex;
}

/* ======================================================================= /*
/* ============================= CSS Effects ============================= /*
/* ======================================================================= /*


/*========================= 'Radial Out' CSS effect ====================== */

.hvr-radial-out {
    /* display: inline-block; */
    /* vertical-align: middle; */
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    /* box-shadow: 0 0 1px rgba(0, 0, 0, 0); */
    position: relative;
    overflow: hidden;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
  .hvr-radial-out:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(62, 62, 62);
    border-radius: 100%;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hvr-radial-out:hover, .hvr-radial-out:focus, .hvr-radial-out:active {
    color: white;
  }
  .hvr-radial-out:hover:before, .hvr-radial-out:focus:before, .hvr-radial-out:active:before {
    -webkit-transform: scale(2);
    transform: scale(2);
  }

/* ======================================================================= */
/* ============================ Media Queries ============================ */
/* ======================================================================= */

@media (max-width: 1000px) and (min-width: 781px) {
  
}

@media (max-width: 580px) {
  .icon-and-title {
    height: 50px;
    width: 50px;
  }

  #inner-wrap {
    width: 310px;
  }

  .icon-and-title {
    display: flex;
    justify-content: center;
  }

  .icon-and-title p {
    display: none;
  }

  #skills-wrap {
    height: 400px;
  }
}
#my-projects-wrap {
    height: 1800px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgb(247, 247, 247);
}

.my-projects-title {
    margin-top: -60px;
    margin-bottom: -80px;
}

.project-and-info-wrap {
    height: 300px;
    width: 900px;
    /* border: 3px solid black; */
    display: flex;
    justify-content: space-between;
}

.project {
    width: 400px;
    height: 300px;
    display: flex;
    flex-direction: column;
    /* border: 1px solid blue; */
    justify-content: center;
}

.info {
    /* justify-content: space-around; */
    justify-content: space-between;
    max-height: 267px;
}

.image {
    justify-content: center;
}

.flip {
    align-items: flex-end !important;
    text-align: right;
    padding-left: 20px;
}


.button {
    height: 30px;
    width: 110px;
    background-color: rgb(80, 80, 80);
    box-shadow: 0 0 5px 2px rgba(0,0,0,.10);
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: white;
}

.button:hover {
    background-color: rgb(180, 180, 180)
}

.methods {
    font-weight: bold;
    color: rgb(80, 80, 80)
}

section {
    display: flex;
    width: 240px;
    justify-content: space-between;
}

img {
    box-shadow: 0 0 5px 2px rgba(0,0,0,.20);
    width: 400px;
}


/* ======================================================================= */
/* ============================= CSS Effects ============================= */
/* ======================================================================= */

/*========================= 'Grow' ====================== */

.hvr-grow {
    display: flex;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
}
.hvr-grow:hover, .hvr-grow:focus, .hvr-grow:active {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

/* ======================================================================= */
/* ============================ Media Queries ============================ */
/* ======================================================================= */

@media (max-width: 1000px) and (min-width: 781px) {

    .project-and-info-wrap {
        width: 720px;
    }

    #pink-desert {
        padding-right: 13px;
    }

}

/* @media (max-width: 780px) and (min-width: 320px) {  */
@media (max-width: 780px) { 

    #my-projects-wrap {
        height: 2500px;
    }

    .reverse {
        flex-direction: column-reverse !important;
        align-items: center;
        height: 487px;
        justify-content: space-between;
        width: 100%;
    }
    
    .project-and-info-wrap {
        flex-direction: column;
        align-items: center;
        height: 487px;
        justify-content: space-between;
        width: 100%;
    }

    .project {
        max-height: 200px;
    }

    .info {
        align-items: center!important;
    }
    
    /* .project.info.flip {
        padding-left: none !important;
    } */

    .project p {
        text-align: center;
    }
    

    section {
        text-align: center;
        width: 300px;
    }

    #pink-desert {
        padding-right: 0 !important;
    }

    .flip {
        padding-left: 0 !important;
    }
}

@media (max-width: 580px) {

    .image-wrap {
        display: flex;
        justify-content: center;
    }

    .image-wrap img {
        width: 300px;
        height: 198px;
    }

    .project {
        max-height: 180px;
        width: 299px;
    }

    .project p {
        font-size: 13px;
    }

    .project h2 {
        font-size: 22px;
    }

    .project section {
        width: 250px;
    }

    .project-and-info-wrap {
        height: 400px;
    }

    .my-projects-title {
        margin-bottom: -76px;
    }

    #my-projects-wrap {
        height: 2100px;
    }
}
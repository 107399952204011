#contact {
    text-align: center;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgb(52, 52, 52);
    font: rgb(214, 214, 214);
}

#contact-title {
    color: rgb(214, 214, 214);
}

.contact-icons {
    display: flex;
}

.fab {
    padding: 0 40px;
    text-decoration: none;
    color: rgb(214, 214, 214);
}

.email {
    text-decoration: none;
    color: rgb(214, 214, 214);
}

/*========================= 'Grow' ====================== */

.hvr-grow {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
}
.hvr-grow:hover, .hvr-grow:focus, .hvr-grow:active {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}


/* ============================ 'Underline' ==================================== */

.hvr-underline-from-center {
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
    padding-bottom: 6px;
}
.hvr-underline-from-center:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 51%;
    right: 51%;
    bottom: 0;
    background: rgb(139, 139, 139);
    height: 4px;
    -webkit-transition-property: left, right;
    transition-property: left, right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    border-radius: 10px;
}
.hvr-underline-from-center:hover:before, .hvr-underline-from-center:focus:before, .hvr-underline-from-center:active:before {
    left: 0;
    right: 0;
}

@media (max-width: 580px) {

    .contact-icons a {
        padding: 0 15px;
    }
}


/* =============================== Gooey Menu effect ==============================*/

//vars
// $fg: rgb(208, 208, 208);
// $pi:3.14;

// //config
// $menu-items:3;
// $open-distance:170px;
// $opening-angle:$pi - .8;


// a{
//   color:inherit;
// }
// h1, h2, h3, h4{
//   margin:0;
//   margin-bottom:10px;
//   margin-top:10px;
// }
// h1{
//   font-size:3em;
// }
// %goo{
//   filter:url('#shadowed-goo');
//   // debug 
//   // background:rgba(255,0,0,0.2);
// }
// %ball{
//   background:$fg;
//   border-radius:100%;
//   width:80px;
//   height:80px;
//   margin-left:-40px;
//   position:absolute;
//   top:20px;
//   color:white;
//   text-align:center;
//   line-height:80px;
//   transform:translate3d(0,0,0);
//   transition:transform ease-out 200ms;
// }
// .menu-open{
//   display:none;
// }
// .menu-item{
//   @extend %ball;
// }
// .hamburger{
//   $width:25px;
//   $height:3px;
//   width:$width;
//   height:$height;
//   background:white;
//   display:block;
//   position:absolute;
//   top:50%;
//   left:50%;
//   margin-left:-$width/2;
//   margin-top:-$height/2;
//   transition:transform 200ms;
// }
// $hamburger-spacing:8px;
// .hamburger-1{
//   transform:translate3d(0,-$hamburger-spacing,0);
// }
// .hamburger-2{
//   transform:translate3d(0,0,0);
// }
// .hamburger-3{
//   transform:translate3d(0,$hamburger-spacing,0);
// }
// .menu-open:checked+.menu-open-button{
//   .hamburger-1{
//     transform:translate3d(0,0,0) rotate(45deg); 
//   }
//   .hamburger-2{
//     transform:translate3d(0,0,0) scale(0.1,1);
//   }
//   .hamburger-3{
//     transform:translate3d(0,0,0) rotate(-45deg); 
//   }
// }
// .menu{
//   @extend %goo;
//   $width:380px;
//   $height:250px;
//   position:absolute;
//   left:50%;
//   margin-left:-$width/2;
//   padding-top:20px;
//   padding-left:$width/2;
//   width:$width;
//   height:$height;
//   box-sizing:border-box;
//   font-size:20px;
//   text-align:left;
// }


// .menu-item{
//   &:hover{
//     background:white;
//     color:$fg;
//   }
//   @for $i from 1 through $menu-items{
//     &:nth-child(#{$i+2}){
//       transition-duration:10ms+(60ms*($i));
//     }
//   }
// }

// .menu-open-button{
//   @extend %ball;
//   z-index:2;
//   transition-timing-function:cubic-bezier(0.175, 0.885, 0.320, 1.275);
//   transition-duration:400ms;
//   transform:scale(1.1,1.1) translate3d(0,0,0);
//   cursor:pointer;
// }
// .menu-open-button:hover{
//   transform:scale(1.2,1.2) translate3d(0,0,0);
// }
// .menu-open:checked+.menu-open-button{
//   transition-timing-function:linear;
//   transition-duration:200ms;
//   transform:scale(0.8,0.8) translate3d(0,0,0);
// }

// .menu-open:checked~.menu-item{
//   transition-timing-function:cubic-bezier(0.935, 0.000, 0.340, 1.330);
//   @for $i from 1 through $menu-items{
//     $angle:(($pi - $opening-angle)/2)+(($opening-angle/($menu-items - 1))*($i - 1));
    
//     &:nth-child(#{$i+2}){
//       transition-duration:280ms+(180ms*$i);
//       transform:translate3d(cos($angle)*$open-distance,sin($angle)*$open-distance,0);
//     }
//   }
// }


.navbar {
    font-size: 18px;
    background-color: rgb(60, 60, 60);
    padding-bottom: 10px;
}

.main-nav {
    list-style-type: none;
    display: none;
}

.nav-links,
.logo {
    text-decoration: none;
    color: rgb(150, 150, 150);
}

.main-nav li {
    text-align: center;
    margin: 15px auto;
}

.logo {
    display: inline-block;
    font-size: 22px;
    margin-top: 10px;
    margin-left: 20px;
}

.navbar-toggle {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer; 
    color: rgba(255,255,255,0.8);
    font-size: 24px;
}

.active {
    display: block;
}

@media screen and (min-width: 781px) {
    .navbar {
        display: flex;
        justify-content: space-between;
        padding-bottom: 0;
        height: 70px;
        align-items: center;
    }

    .main-nav {
        display: flex;
        margin-right: 30px;
        flex-direction: row;
        justify-content: flex-end;
    }

    .main-nav li {
        margin: 0;
    }

    .nav-links {
        margin-left: 40px;
    }

    .logo {
        margin-top: 0;
    }

    .navbar-toggle {
        display: none;
    }

    /* .logo:hover,
    .nav-links:hover {
        color: rgba(255, 255, 255, 1);
    } */
}

/* ============================ 'Underline' ==================================== */

.hvr-underline-from-center {
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
    padding-bottom: 6px;
}
.hvr-underline-from-center:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 51%;
    right: 51%;
    bottom: 0;
    background: rgb(139, 139, 139);
    height: 4px;
    -webkit-transition-property: left, right;
    transition-property: left, right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    border-radius: 10px;
}
.hvr-underline-from-center:hover:before, .hvr-underline-from-center:focus:before, .hvr-underline-from-center:active:before {
    left: 0;
    right: 0;
}

.main {
    height: 500px;
    background: #C9CCD3;
    /* background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0.6) 0%, rgba(0,0,0,0.50) 100%);
    background-blend-mode: lighten; */
    background-color: #5b6467;
    background-image: -webkit-linear-gradient(135deg, #C2C5CD 0%, #DCDCDC 74%);
    background-image: linear-gradient(315deg, #C2C5CD 0%, #DCDCDC 74%);
    justify-content: center;
    display: flex;
    align-items: center;
}

#name-wrap {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.my-name {
    width: 500px;
    box-shadow: none;
    /* padding-bottom: 5px; */
}

.my-name:hover {
    box-shadow: none;
}

#line {
    width: 300px;
    border-bottom: 1px solid rgb(60, 60, 60, .5);;
    height: 10px;
    padding-top: 15px;
}

#name-wrap h2 {
    color: black;
    font-weight: 300;
    padding-top: 15px;
}

#about-me-wrap {
    height: 50vh;
    display: flex;
    justify-content: center;
}

@media (max-width: 580px) {

    .my-name {
        width: 350px;
    }

    #name-wrap h2 {
        font-size: 18px;
    }

    .main {
        height: 300px;
    }

    #line {
        width: 270px;

    }
}

@media (max-width: 420px) {

    .my-name {
        width: 275px;
        /* padding-bottom: 10px; */
    }

    #name-wrap h2 {
        font-size: 16px;
    }

    #line {
        width: 200px;

    }
}
#about-me-wrap {
    width: 100vw;
    height: 550px;
    align-items: center;
    background-color: rgb(60, 60, 60);
}

#pic-and-text-wrap {
    min-width: 760px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid green; */
}

#my-pic {
    width: auto;
    height: 300px;
    border-radius: 100%;
    margin-left: 45px;
}

#text-wrap {
    width: 350px;
    min-width: 350px;
    line-height: 25px;
    color: rgba(255,255,255,0.8)
}


/* ======================================================================= */
/* ============================ Media Queries ============================ */
/* ======================================================================= */

/* CSS effects */
@media (min-width: 1000px) {

/*========================= 'Rotate' ====================== */

.hvr-rotate {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
}
.hvr-rotate:hover, .hvr-rotate:focus, .hvr-rotate:active {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
}

/*==================== 'Forward (slide right)' ================= */

.hvr-forward {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
}
.hvr-forward:hover, .hvr-forward:focus, .hvr-forward:active {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
}

}

@media (max-width: 1000px) {

    #pic-and-text-wrap {
        min-width: 722px;
    }
}

/* @media (max-width: 780px) and (min-width: 320px) { */
@media (max-width: 780px) {

    #pic-and-text-wrap {
        flex-direction: column;
        min-width: 0;

    }

    #my-pic {
        margin-left: 0;
        height: 250px;
        margin-bottom: 23px;
    }

    #text-wrap {
        text-align: center;
        line-height: 18px;
        font-size: 15px;
    }
}

@media (max-width: 420px) {
 
    #text-wrap {
        /* font-size: 13px; */
        min-width: 300px;
        width: 300px;
    }

    .text {
        font-size: 13px;
    }

}
#skills-wrap {
    height: 575px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #5b6467;
    background-image: -webkit-linear-gradient(135deg, #C2C5CD 0%, #DCDCDC 74%);
    background-image: linear-gradient(315deg, #C2C5CD 0%, #DCDCDC 74%);
    /* background: #C9CCD3;
    background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0.6) 0%, rgba(0,0,0,0.50) 100%);
    background-blend-mode: lighten; */
}

#skills-title {
    padding-bottom: 20px;
}


#inner-wrap {
    width: 560px;
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    /* overflow: scroll; */
    justify-content: center;
    background-color: white;
    border-radius: 10px;
}

.icon-and-title {
    display: flex;
    flex-direction: column;
    margin: 5px;
    text-align: center;
    background-color: rgb(210, 211, 214);
    border-radius: 5px;;
    padding: 10px;
    height: 90px;
    width: 100px;
    font-size: 13px;
}

.icon-and-title p {
    padding-bottom: 5px;
}

.five-icons {
    display: flex;
}

/* ======================================================================= /*
/* ============================= CSS Effects ============================= /*
/* ======================================================================= /*


/*========================= 'Radial Out' CSS effect ====================== */

.hvr-radial-out {
    /* display: inline-block; */
    /* vertical-align: middle; */
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    /* box-shadow: 0 0 1px rgba(0, 0, 0, 0); */
    position: relative;
    overflow: hidden;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
  .hvr-radial-out:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(62, 62, 62);
    border-radius: 100%;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition-property: transform;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hvr-radial-out:hover, .hvr-radial-out:focus, .hvr-radial-out:active {
    color: white;
  }
  .hvr-radial-out:hover:before, .hvr-radial-out:focus:before, .hvr-radial-out:active:before {
    -webkit-transform: scale(2);
    transform: scale(2);
  }

/* ======================================================================= */
/* ============================ Media Queries ============================ */
/* ======================================================================= */

@media (max-width: 1000px) and (min-width: 781px) {
  
}

@media (max-width: 580px) {
  .icon-and-title {
    height: 50px;
    width: 50px;
  }

  #inner-wrap {
    width: 310px;
  }

  .icon-and-title {
    display: flex;
    justify-content: center;
  }

  .icon-and-title p {
    display: none;
  }

  #skills-wrap {
    height: 400px;
  }
}
#my-projects-wrap {
    height: 1800px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgb(247, 247, 247);
}

.my-projects-title {
    margin-top: -60px;
    margin-bottom: -80px;
}

.project-and-info-wrap {
    height: 300px;
    width: 900px;
    /* border: 3px solid black; */
    display: flex;
    justify-content: space-between;
}

.project {
    width: 400px;
    height: 300px;
    display: flex;
    flex-direction: column;
    /* border: 1px solid blue; */
    justify-content: center;
}

.info {
    /* justify-content: space-around; */
    justify-content: space-between;
    max-height: 267px;
}

.image {
    justify-content: center;
}

.flip {
    align-items: flex-end !important;
    text-align: right;
    padding-left: 20px;
}


.button {
    height: 30px;
    width: 110px;
    background-color: rgb(80, 80, 80);
    box-shadow: 0 0 5px 2px rgba(0,0,0,.10);
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: white;
}

.button:hover {
    background-color: rgb(180, 180, 180)
}

.methods {
    font-weight: bold;
    color: rgb(80, 80, 80)
}

section {
    display: flex;
    width: 240px;
    justify-content: space-between;
}

img {
    box-shadow: 0 0 5px 2px rgba(0,0,0,.20);
    width: 400px;
}


/* ======================================================================= */
/* ============================= CSS Effects ============================= */
/* ======================================================================= */

/*========================= 'Grow' ====================== */

.hvr-grow {
    display: flex;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
}
.hvr-grow:hover, .hvr-grow:focus, .hvr-grow:active {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

/* ======================================================================= */
/* ============================ Media Queries ============================ */
/* ======================================================================= */

@media (max-width: 1000px) and (min-width: 781px) {

    .project-and-info-wrap {
        width: 720px;
    }

    #pink-desert {
        padding-right: 13px;
    }

}

/* @media (max-width: 780px) and (min-width: 320px) {  */
@media (max-width: 780px) { 

    #my-projects-wrap {
        height: 2500px;
    }

    .reverse {
        flex-direction: column-reverse !important;
        align-items: center;
        height: 487px;
        justify-content: space-between;
        width: 100%;
    }
    
    .project-and-info-wrap {
        flex-direction: column;
        align-items: center;
        height: 487px;
        justify-content: space-between;
        width: 100%;
    }

    .project {
        max-height: 200px;
    }

    .info {
        align-items: center!important;
    }
    
    /* .project.info.flip {
        padding-left: none !important;
    } */

    .project p {
        text-align: center;
    }
    

    section {
        text-align: center;
        width: 300px;
    }

    #pink-desert {
        padding-right: 0 !important;
    }

    .flip {
        padding-left: 0 !important;
    }
}

@media (max-width: 580px) {

    .image-wrap {
        display: flex;
        justify-content: center;
    }

    .image-wrap img {
        width: 300px;
        height: 198px;
    }

    .project {
        max-height: 180px;
        width: 299px;
    }

    .project p {
        font-size: 13px;
    }

    .project h2 {
        font-size: 22px;
    }

    .project section {
        width: 250px;
    }

    .project-and-info-wrap {
        height: 400px;
    }

    .my-projects-title {
        margin-bottom: -76px;
    }

    #my-projects-wrap {
        height: 2100px;
    }
}
#contact {
  text-align: center;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: #343434;
  font: #d6d6d6; }

#contact-title {
  color: #d6d6d6; }

.contact-icons {
  display: flex; }

.fab {
  padding: 0 40px;
  text-decoration: none;
  color: #d6d6d6; }

.email {
  text-decoration: none;
  color: #d6d6d6; }

/*========================= 'Grow' ====================== */
.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform; }

.hvr-grow:hover, .hvr-grow:focus, .hvr-grow:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1); }

/* ============================ 'Underline' ==================================== */
.hvr-underline-from-center {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
  padding-bottom: 6px; }

.hvr-underline-from-center:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 51%;
  right: 51%;
  bottom: 0;
  background: #8b8b8b;
  height: 4px;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  border-radius: 10px; }

.hvr-underline-from-center:hover:before, .hvr-underline-from-center:focus:before, .hvr-underline-from-center:active:before {
  left: 0;
  right: 0; }

@media (max-width: 580px) {
  .contact-icons a {
    padding: 0 15px; } }

/* =============================== Gooey Menu effect ==============================*/

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  font-family: 'Josefin Sans', sans-serif;
}

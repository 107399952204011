.main {
    height: 500px;
    background: #C9CCD3;
    /* background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0.6) 0%, rgba(0,0,0,0.50) 100%);
    background-blend-mode: lighten; */
    background-color: #5b6467;
    background-image: linear-gradient(315deg, #C2C5CD 0%, #DCDCDC 74%);
    justify-content: center;
    display: flex;
    align-items: center;
}

#name-wrap {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.my-name {
    width: 500px;
    box-shadow: none;
    /* padding-bottom: 5px; */
}

.my-name:hover {
    box-shadow: none;
}

#line {
    width: 300px;
    border-bottom: 1px solid rgb(60, 60, 60, .5);;
    height: 10px;
    padding-top: 15px;
}

#name-wrap h2 {
    color: black;
    font-weight: 300;
    padding-top: 15px;
}

#about-me-wrap {
    height: 50vh;
    display: flex;
    justify-content: center;
}

@media (max-width: 580px) {

    .my-name {
        width: 350px;
    }

    #name-wrap h2 {
        font-size: 18px;
    }

    .main {
        height: 300px;
    }

    #line {
        width: 270px;

    }
}

@media (max-width: 420px) {

    .my-name {
        width: 275px;
        /* padding-bottom: 10px; */
    }

    #name-wrap h2 {
        font-size: 16px;
    }

    #line {
        width: 200px;

    }
}
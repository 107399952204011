#about-me-wrap {
    width: 100vw;
    height: 550px;
    align-items: center;
    background-color: rgb(60, 60, 60);
}

#pic-and-text-wrap {
    min-width: 760px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid green; */
}

#my-pic {
    width: auto;
    height: 300px;
    border-radius: 100%;
    margin-left: 45px;
}

#text-wrap {
    width: 350px;
    min-width: 350px;
    line-height: 25px;
    color: rgba(255,255,255,0.8)
}


/* ======================================================================= */
/* ============================ Media Queries ============================ */
/* ======================================================================= */

/* CSS effects */
@media (min-width: 1000px) {

/*========================= 'Rotate' ====================== */

.hvr-rotate {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
}
.hvr-rotate:hover, .hvr-rotate:focus, .hvr-rotate:active {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
}

/*==================== 'Forward (slide right)' ================= */

.hvr-forward {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
}
.hvr-forward:hover, .hvr-forward:focus, .hvr-forward:active {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
}

}

@media (max-width: 1000px) {

    #pic-and-text-wrap {
        min-width: 722px;
    }
}

/* @media (max-width: 780px) and (min-width: 320px) { */
@media (max-width: 780px) {

    #pic-and-text-wrap {
        flex-direction: column;
        min-width: 0;

    }

    #my-pic {
        margin-left: 0;
        height: 250px;
        margin-bottom: 23px;
    }

    #text-wrap {
        text-align: center;
        line-height: 18px;
        font-size: 15px;
    }
}

@media (max-width: 420px) {
 
    #text-wrap {
        /* font-size: 13px; */
        min-width: 300px;
        width: 300px;
    }

    .text {
        font-size: 13px;
    }

}